<template>
    <CommonLayout>
        <div class="home py-5 w-11">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="home__content">
                                <h1 class="home__title">邀您共赴财富增长的盛宴</h1>
                                <p class="home__text"> 松果道不仅是投资的天地，更是实现您财富自由梦想的起点。现在就加入我们，共享多元化的收益，启程您的财富旅程！ </p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <img src="assets/img/cow.jpeg" class="w-100" alt="">
                        </div>
                    </div>
                </div>
            </div>

    </CommonLayout>
</template>

<script>

import { defineComponent } from 'vue'
import CommonLayout from '@/components/CommonLayout.vue'

export default defineComponent({
    name: 'EarnPage',
    components: {
        CommonLayout
    },
    setup() {

    },

})
</script>

<style scoped></style>