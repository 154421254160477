
import { useWeb3 } from '@/composables/useWeb3';
import { computed } from 'vue';

import Contract_ABI from "./abis/MultiCall.json";
import contractAddresses from "./contractAddress.json";

export function useMulticall() {
    const { web3,state } = useWeb3();

    const currentContractAddresses = computed(() => {
        const chainId = state.chainId;//await web3.value.eth.getChainId();
        return contractAddresses[chainId].multiCall;
    });

    const contract = computed( () => {
        const currentAddress = currentContractAddresses.value;
        if (web3.value && currentAddress) {
            let from;
            if (web3.value.currentProvider.selectedAddress) {
                from = web3.value.currentProvider.selectedAddress;
            } else if (web3.value.eth.accounts.wallet.length > 0) {
                from = web3.value.eth.accounts.wallet[0].address;
            } else {
                from = null; // 如果没有连接钱包，from 为 null
            }
            return new web3.value.eth.Contract(Contract_ABI.abi, currentAddress, {
                from: from
            });
        }
        return null;
    });

    // const contract = computed(() => {
    //     const currentAddress = await currentContractAddresses.value;
    //     if (web3.value && currentAddress) {
    //         let from;
    //         const accounts = await web3.value.eth.getAccounts();
    //         if (web3.value.currentProvider.selectedAddress) {
    //             from = web3.value.currentProvider.selectedAddress;
    //         } else if (accounts.length > 0) {
    //             from = accounts[0];
    //         } else {
    //             from = null; // 如果没有连接钱包，from 为 null
    //         }
    //         console.log('multicall address', currentAddress);
    //         // console.log('multicall abi', Contract_ABI.abi);
    //         return new web3.value.eth.Contract(Contract_ABI.abi, currentAddress, {
    //             from: from
    //         });
    //     }
    //     return null;
    // });

    async function multiCallForSingle(targetContractAddress, functionSelectors) {
        console.log('multiCallForSingle-targetContractAddress',targetContractAddress);
        console.log('multiCallForSingle-web3 current chainId',await web3.value.eth.getChainId());

        const contractMulticall = await contract.value;
        // console.log('contractMulticall',contractMulticall.methods)
        const results = contractMulticall.methods.multiCallForSingle(targetContractAddress, functionSelectors).call();
        return results;
    }

    return {
        contract,
        multiCallForSingle
    };
}
