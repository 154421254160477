import { createContract } from './createContract';
import { useWeb3 } from '@/composables/useWeb3';

import Contract_ABI from "./abis/Rewards.json";

export function useRewards() {
    const baseContract = createContract(useWeb3, 'rewardAddress', Contract_ABI.abi);
    const { contract, callTargetFunctions } = baseContract;

    async function getTokenInfo(...args) {
        const result = await callTargetFunctions(...args);
        return result;
    }

    return {
        contract,
        getTokenInfo
    };
}
