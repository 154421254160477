import { createApp } from 'vue'

import { BootstrapVueNext } from 'bootstrap-vue-next'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/dark/css-vars.css';

import App from './App.vue'
// import PrimeVue from 'primevue/config';
// import 'primevue/resources/themes/saga-blue/theme.css';
// import 'primevue/resources/primevue.min.css';
// import 'primeicons/primeicons.css';



import { createMetaManager } from 'vue-meta';
import router from "./router"
import store from "./store"
import { createI18n } from 'vue-i18n'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from './locales/en.json'
import zhCN from './locales/zh-CN.json'
import zhTW from './locales/zh-TW.json'

let userLanguage = navigator.language || navigator.userLanguage;
userLanguage = userLanguage.toLowerCase();
console.log('userLanguage',userLanguage);
let locale = 'en';
// if (userLanguage === 'zh-tw') {
//     locale = 'zhTW';
// } else if (userLanguage === 'zh-cn') {
//     locale = 'zhCN';
// }

// 从localStorage中读取语言设置
let savedLocale = localStorage.getItem('locale');

if (savedLocale) {
    console.log('savedLocale',savedLocale);
    // 如果localStorage中有保存的语言设置，使用它
    locale = savedLocale;
} else {
    // 否则，根据浏览器的语言设置确定语言
    if (userLanguage === 'zh-tw') {
        locale = 'zhTW';
    } else if (userLanguage === 'zh-cn') {
        locale = 'zhCN';
    }
    // 将语言设置保存到localStorage中
    localStorage.setItem('locale', locale);
}


const i18n = createI18n({
    legacy: false,
    locale,
    messages: {
        en,
        zhCN,
        zhTW
    }
})


const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}


app.use(router);

app.use(ElementPlus, {
    locale: zhCn,
  });
  
  app.use(i18n);
  app.use(store);


  // 创建并添加 Meta Manager
const metaManager = createMetaManager();
app.use(metaManager);

// app.use(PrimeVue, { inputStyle: "filled" });

app.use(BootstrapVueNext);

app.mount("#app");