<template>
    <BOffcanvas v-model="visible" @hidden="onHidden" ref="offcanvas" class="pine-bg-dark w-full pine-close-button">
        <template #header>
            <div class="flex justify-content-between w-full">
                <h5>Pinecone DAO</h5>
                <el-icon :size="26" @click="hideOffcanvas()">
                    <Close />
                </el-icon>
            </div>
        </template>

        <b-nav class="flex-column">
            <b-nav-item href="/" class="pine-dropdown">
                        {{ $t('Home') }}
                    </b-nav-item>
            <b-nav-item
                href="https://docs.pineconedao.com/v/zh-cn/12.-yong-hu-zhi-nan/song-guo-dao-xin-shou-zhi-nan-kai-qi-nin-de-zhi-hui-tou-zi-yu-cai-fu-zeng-chang-zhi-lv"
                class="pine-dropdown">{{ $t('guidance') }}</b-nav-item>
            <b-nav-item href="https://list.pineconedao.com" class="pine-dropdown">{{ $t('Project')
            }}</b-nav-item>
            <b-nav-item :href="$t('faq-url')" class="pine-dropdown">{{ $t('faq') }}</b-nav-item>
            <b-nav-item :href="$t('whitePaper-url')" class="pine-dropdown">{{ $t('whitePaper') }}</b-nav-item>

            <b-nav-item
                href="https://quickswap.exchange/#/swap?currency0=0xE1b3d3f9302FB84C8d5B9e13cd8160fEE67Ea239&currency1=ETH&swapIndex=0&isProMode=true"
                class="pine-dropdown" target="_blank">
                {{ $t('Trade') }}
            </b-nav-item>

            <b-nav-item class="pine-dropdown" href="/earn">
                {{ $t('Earn') }}
            </b-nav-item>

            <b-nav-item class="pine-dropdown" href="https://snapshot.org/#/pineconedao.eth" target="_blank">
               {{ $t('Voting') }}
            </b-nav-item>

            <b-nav-item class="pine-dropdown" :href="$t('chart-url')" target="_blank">
                        {{ $t('chart') }}
            </b-nav-item>

            <b-nav-item-dropdown :text="$t('language')" right class="pine-dropdown">
                <b-dropdown-item @click="changeLanguage('en')">{{ $t('English') }}</b-dropdown-item>
                <b-dropdown-item @click="changeLanguage('zhCN')">{{ $t('Simplified Chinese')
                }}</b-dropdown-item>
                <b-dropdown-item @click="changeLanguage('zhTW')">{{ $t('Traditional Chinese')
                }}</b-dropdown-item>
            </b-nav-item-dropdown>
        </b-nav>

    </BOffcanvas>
</template>

<script>
import { ref, watch} from 'vue'
import { useI18n } from 'vue-i18n';


export default {
    props: {
        visibleCanvas: {
            type: Boolean,
            required: true
        }
    },
    emits: ['menuClick', 'updateVisible'],
    setup(props,{ emit }) {

        const offcanvas = ref(null);
        const visible = ref(false);

        function hideOffcanvas() {
            visible.value = false;
        }

        function onHidden() {
            visible.value = false;
        }

        const { locale } = useI18n();
        const changeLanguage = (newLocale) => {
            locale.value = newLocale;
            localStorage.setItem('locale', newLocale);
        }

        const openApp = () => {
            window.open('https://app.pineconedao.com', '_blank');
        };

        watch(() => props.visibleCanvas, (newValue, oldValue) => {
            console.log('visibleCanvas changed from', oldValue, 'to', newValue);
            visible.value = newValue;
        });

        watch(visible, (newValue, oldValue) => {
            console.log('visible changed from', oldValue, 'to', newValue);
            emit('updateVisible', newValue);
        });

        return {
            changeLanguage,
            openApp,
            hideOffcanvas,
            onHidden,
            offcanvas,
            visible
        }
    }
}
</script>

<style scoped>
.pine-close-button .btn-close {
    color: #fff !important;
}
</style>