
import { createRouter, createWebHistory } from "vue-router";
// import InvitePage from "../views/invite.vue";
import IndexPage from "../views/index.vue";
import EarnPage from "../views/earn.vue";
// import JoinPage from "../views/join.vue";

const routes = [
  {
    path: "/",
    name: "IndexPage",
    component: IndexPage
  },
  {
    path: "/earn",
    name: "/EarnPage",
    component: EarnPage,
  },
  {
    path: "/join/:id/",
    name: "joinPage",
    component: () => import("../views/join.vue"),
  },
  {
    path: "/core/:id/",
    name: "corePage",
    component: () => import("../views/core.vue"),
  }
  // 其他路由...
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
