<template>
    <div>

        <div class="flex justify-content-center bg-color-2"
            style="transform: translateY(50px); padding: 100px 30px; overflow-x: hidden;">

            <el-row :gutter="20" class="pine-main">
                <el-col :span="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom:20px;"
                    class="flex justify-content-center flex flex-column">

                    <el-text class="title-h3">{{ $t('welcome') }}</el-text>
                    <el-text class="title-desc"
                        style="margin-top:20px;text-align:left;">{{ $t('intro') }}</el-text>
                    <el-text class="title-desc"
                        style="margin-top:20px;text-align:left;">{{ $t('joinMessage') }}</el-text>

                    <div class="flex justify-content-center">
                        <el-button style="margin-top:50px;width:120px;font-weight:800;" color="#ffae01" size="large"
                            round @click="goToJoin(1)">{{ $t('joinNow') }}</el-button>
                    </div>

                </el-col>
            </el-row>


        </div>


        <div class="flex justify-content-center bg-color-1"
            style="transform: translateY(50px); padding: 100px 30px; background-color: #00002e; overflow-x: hidden;">


            <div class="pine-card-box pine-main">
    <div class="flex justify-content-center">
        <el-text class="title-h3">{{ $t('visionTitle') }}</el-text>
    </div>

    <el-row :gutter="20" style="margin-top:30px;">
        <el-col :span="24" :sm="7" :md="7" :lg="7" :xl="7" style="margin-bottom:20px;" class="flex flex-column pine-rectangle">
            <el-text class="title-h5" style="width:100%;text-align:left;color:#fdbc2d;">{{ $t('daoTitle') }}</el-text>
            <el-text class="title-desc" style="margin-top:20px;text-align:left;">{{ $t('daoDesc') }}</el-text>
        </el-col>
        <el-col :span="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
        <el-col :span="24" :sm="8" :md="8" :lg="8" :xl="8" style="margin-bottom:20px;" class="flex justify-content-start align-items-start flex-column pine-rectangle">
            <el-text class="title-h5" style="width:100%;text-align:left;color:#da435f;">{{ $t('miningTitle') }}</el-text>
            <el-text class="title-desc" style="margin-top:20px;text-align:left;">{{ $t('miningDesc') }}</el-text>
        </el-col>
        <el-col :span="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
        <el-col :span="24" :sm="7" :md="8" :lg="7" :xl="7" style="margin-bottom:20px;" class="flex justify-content-start flex flex-column pine-rectangle">
            <el-text class="title-h5" style="width:100%;text-align:left;color:#e27a5f;">{{ $t('etfTitle') }}</el-text>
            <el-text class="title-desc" style="margin-top:20px;text-align:left;">{{ $t('etfDesc') }}</el-text>
        </el-col>
    </el-row>
</div>



        </div>




        <div class="flex justify-content-center bg-color-3"
            style="transform: translateY(50px); padding: 100px 30px; background-color: #00002e; overflow-x: hidden;">


            <div class="pine-card-box pine-main">
                <div class="flex justify-content-center">
                    <el-text class="title-h3">{{ $t('miningTitle') }}</el-text>
                </div>


                <el-row :gutter="20" style="margin-top:30px;">
                    <el-col :span="24" :sm="7" :md="7" :lg="7" :xl="7" style="margin-bottom:20px;"
                        class="flex flex-column pine-rectangle">

                        <el-text class="title-desc" style="margin-top:20px;text-align:left;width:100%;">{{ $t('oneTreeTitle') }}</el-text>
                        <el-text class="title-h3" style="width:100%;text-align:left;color:#fdbc2d;">$15</el-text>
                        <el-text class="title-desc"
                            style="margin-top:20px;text-align:left;">{{ sprintf( $t('oneTreeDesc'), 15) }}</el-text>
                        <div class="flex justify-content-center">
                            <el-button @click="goToJoin(1)" style="margin-top:50px;width:120px;font-weight:800;" color="#ffae01" size="large"
                                round>{{ $t('joinNow') }}</el-button>
                        </div>
                    </el-col>
                    <el-col :span="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
                    <el-col :span="24" :sm="8" :md="8" :lg="8" :xl="8" style="margin-bottom:20px;"
                        class="flex justify-content-start align-items-start flex-column pine-rectangle">

                        <el-text class="title-desc"
                            style="margin-top:20px;text-align:left;width:100%;">{{ $t('tenTreeTitle') }}</el-text>
                        <el-text class="title-h3" style="width:100%;text-align:left;color:#da435f;">$150</el-text>
                        <el-text class="title-desc"
                            style="margin-top:20px;text-align:left;">{{ $t('tenTreeDesc') }}</el-text>

                        <div class="flex justify-content-center" style="width:100%;">
                            <el-button style="margin-top:50px;width:120px;font-weight:800;" color="#ffae01" size="large"
                                round @click="goToJoin(10)">{{ $t('joinNow') }}</el-button>
                        </div>


                    </el-col>
                    <el-col :span="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
                    <el-col :span="24" :sm="7" :md="8" :lg="7" :xl="7" style="margin-bottom:20px;"
                        class="flex justify-content-start flex flex-column pine-rectangle">

                        <el-text class="title-desc"
                            style="margin-top:20px;text-align:left;width:100%;">{{ $t('thirtyTreeTitle') }}</el-text>
                        <el-text class="title-h3" style="width:100%;text-align:left;color:#e27a5f;">$450</el-text>
                        <el-text class="title-desc"
                            style="margin-top:20px;text-align:left;">{{ $t('thirtyTreeDesc') }}</el-text>

                        <div class="flex justify-content-center">
                            <el-button style="margin-top:50px;width:120px;font-weight:800;" color="#ffae01" size="large"
                                round @click="goToJoin(30)">{{ $t('joinNow') }}</el-button>
                        </div>

                    </el-col>

                </el-row>


            </div>


        </div>



        <div class="flex justify-content-center bg-color-4"
            style="transform: translateY(50px); padding: 100px 30px; background-color: #00002e; overflow-x: hidden;">


            <div class="pine-card-box pine-main">
                <div class="flex justify-content-center">
                    <el-text class="title-h3">{{ $t('aiQuantitativeStrategyTitle') }}</el-text>
                </div>


                <el-row :gutter="20" style="margin-top:30px;">
                    <el-col :span="24" :sm="7" :md="7" :lg="7" :xl="7" style="margin-bottom:20px;" class="flex flex-column">


                        <div class="bg-icon">
                            <el-image style="width: 50px; height: 50px;" src="/assets/img/head-icon.svg" fit="cover" />
                        </div>


                        <el-text class="title-h5" style="width:100%;color:#fdbc2d;">{{ $t('powerfulIntelligentDecision') }}</el-text>
                        <el-text class="title-desc"
                            style="margin-top:20px;text-align:left;">{{ $t('powerfulIntelligentDecisionDesc') }}</el-text>
                    </el-col>
                    <el-col :span="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
                    <el-col :span="24" :sm="8" :md="8" :lg="8" :xl="8" style="margin-bottom:20px;"
                        class="flex justify-content-start align-items-start flex-column">

                        <div class="bg-icon">
                            <el-image style="width: 50px; height: 50px;" src="/assets/img/papaya-icon.svg" fit="cover" />
                        </div>

                        <el-text class="title-h5" style="width:100%;color:#da435f;">{{ $t('automatedTrading') }}</el-text>
                        <el-text class="title-desc"
                            style="margin-top:20px;text-align:left;">{{ $t('automatedTradingDesc') }}</el-text>
                    </el-col>
                    <el-col :span="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
                    <el-col :span="24" :sm="7" :md="8" :lg="7" :xl="7" style="margin-bottom:20px;"
                        class="flex justify-content-start flex flex-column">

                        <div class="bg-icon">
                            <el-image style="width: 50px; height: 50px;" src="/assets/img/risk-icon.svg" fit="cover" />
                        </div>

                        <el-text class="title-h5" style="width:100%;color:#e27a5f;">{{ $t('optimizedRiskControl') }}</el-text>
                        <el-text class="title-desc"
                            style="margin-top:20px;text-align:left;">{{ $t('optimizedRiskControlDesc') }}</el-text>
                    </el-col>

                </el-row>


                <el-row :gutter="20" style="margin-top:30px;">
                    <el-col :span="24" :sm="7" :md="7" :lg="7" :xl="7" style="margin-bottom:20px;" class="flex flex-column">

                        <div class="bg-icon">
                            <el-image style="width: 50px; height: 50px;" src="/assets/img/magic-icon.svg" fit="cover" />
                        </div>

                        <el-text class="title-h5" style="width:100%;color:#add8e6;">{{ $t('improveTradingEfficiency') }}</el-text>
                        <el-text class="title-desc"
                            style="margin-top:20px;text-align:left;">{{ $t('improveTradingEfficiencyDesc') }}</el-text>
                    </el-col>
                    <el-col :span="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
                    <el-col :span="24" :sm="8" :md="8" :lg="8" :xl="8" style="margin-bottom:20px;"
                        class="flex justify-content-start align-items-start flex-column">

                        <div class="bg-icon">
                            <el-image style="width: 50px; height: 50px;" src="/assets/img/abacus-icon.svg" fit="cover" />
                        </div>

                        <el-text class="title-h5" style="width:100%;color:#494949;">{{ $t('personalizedCustomization') }}</el-text>
                        <el-text class="title-desc"
                            style="margin-top:20px;text-align:left;">{{ $t('personalizedCustomizationDesc') }}</el-text>
                    </el-col>
                    <el-col :span="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
                    <el-col :span="24" :sm="7" :md="8" :lg="7" :xl="7" style="margin-bottom:20px;"
                        class="flex justify-content-start flex flex-column">

                    </el-col>

                </el-row>


            </div>


        </div>


        <div class="flex justify-content-center bg-color-3"
            style="padding: 100px 30px; background-color: #00002e; overflow-x: hidden;">


            <div class="pine-card-box pine-main">
                <div class="flex justify-content-center">
                    <el-text class="title-h3">{{ $t('market-title') }}</el-text>
                </div>


                <el-row :gutter="20" style="margin-top:30px;">
                    <el-col :span="24" :sm="7" :md="7" :lg="7" :xl="7" style="margin-bottom:20px;"
                        class="flex flex-column">

                        <el-image style="width: 90%; height: 90%; border-radius: 50%; border: 2px solid #fdbc2d; object-fit: cover; object-position: center;" src="/assets/img/nft_key97.jpg_400.jpg" />



                        <el-text class="title-h5" style="width:100%;color:#fdbc2d;margin-top:20px;">{{ $t('key97') }}</el-text>
                        <el-text class="title-desc"
                            style="margin-top:20px;text-align:left;">{{ $t('desc97') }}</el-text>

                    </el-col>
                    <el-col :span="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
                    <el-col :span="24" :sm="8" :md="8" :lg="8" :xl="8" style="margin-bottom:20px;"
                        class="flex justify-content-start align-items-start flex-column">

                        <el-image style="width: 80%;border-radius: 50%; border: 2px solid #da435f;object-fit: cover; object-position: center;" src="/assets/img/nft_key98.jpg_400.jpg" fit="cover" />

                        <el-text class="title-h5" style="width:100%;color:#da435f;margin-top:20px;">{{ $t('key98') }}</el-text>
                        <el-text class="title-desc"
                            style="margin-top:20px;text-align:left;">{{ $t('desc98') }}</el-text>



                    </el-col>
                    <el-col :span="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
                    <el-col :span="24" :sm="7" :md="8" :lg="7" :xl="7" style="margin-bottom:20px;"
                        class="flex justify-content-start flex flex-column">

                        <el-image style="width: 90%;border-radius: 50%; border: 2px solid #e27a5f;" src="/assets/img/nft_key99.jpg_400.jpg" fit="cover" />

                        <el-text class="title-h5" style="width:90%;color:#e27a5f;margin-top:20px;">{{ $t('key99') }}</el-text>
                        <el-text class="title-desc"
                            style="margin-top:20px;text-align:left;">{{ $t('desc99') }}</el-text>


                    </el-col>


                </el-row>

                <el-row style="margin-top:50px;">
                    <el-col :span="24" :sm="7" :md="7" :lg="7" :xl="7" style="margin-bottom:20px;"
                        class="flex flex-column">

                        <el-image style="width: 90%; border-radius: 50%; border: 2px solid #add8e6;" src="/assets/img/nft_key100.jpg_400.jpg" fit="cover" />


                        <el-text class="title-h5" style="width:100%;color:#add8e6;margin-top:20px;">{{ $t('key100') }}</el-text>
                        <el-text class="title-desc"
                            style="margin-top:20px;text-align:left;">{{ $t('desc100') }}</el-text>

                    </el-col>
                    <el-col :span="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
                    <el-col :span="24" :sm="8" :md="8" :lg="8" :xl="8" style="margin-bottom:20px;"
                        class="flex justify-content-start align-items-start flex-column">

                        <el-image style="width: 80%; border-radius: 50%; border: 2px solid #494949;" src="/assets/img/treasurebox.jpg_400.jpg" fit="cover" />


                        <el-text class="title-h5" style="width:100%;color:#494949;margin-top:20px;">{{ $t('blindbox') }}</el-text>
                        <el-text class="title-desc"
                            style="margin-top:20px;text-align:left;">{{ $t('blindbox-desc') }}</el-text>



                    </el-col>
                    <el-col :span="1" :sm="1" :md="1" :lg="1" :xl="1"></el-col>
                    <el-col :span="24" :sm="7" :md="8" :lg="7" :xl="7" style="margin-bottom:20px;"
                        class="flex justify-content-start flex flex-column">

                    </el-col>

                </el-row>


            </div>


        </div>




    </div>
</template>

<script>

import { sprintf } from 'sprintf-js';
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router';
export default defineComponent({
    setup() {
        const router = useRouter();

        const goToJoin = (id) => {
            console.log('goToJoin');
            // window.location.href = `/join/${id}`;
            router.push(`/join/${id}`);
        };

        return {
            goToJoin,
            sprintf
        };
    },
})
</script>



<style lang="scss">
.el-text {
    text-align: center;
}

.text-color {
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, rgb(0, 209, 255) 0%, rgb(0, 209, 255) 15%, rgb(237, 30, 255) 85%, rgb(237, 30, 255) 100%);
    font-family: Impact, sans-serif;
    font-weight: 500;
}

.bg-color-1 {
    background: linear-gradient(to right, #8a70a5, #1b3c83);
}

.pine-card-box {
    background-color: #161444;
    border: 4px solid #262654;
    border-radius: 15px;
    width: 100%;
    padding: 50px;
}

.pine-rectangle {
    background-color: #00002e;
    border: 2px solid #c2902d;
    border-radius: 10px;
    padding: 20px 40px;
}



.bg-color-2 {
    background:
        radial-gradient(circle at 50px 70px, transparent 95px, #132252 100px, #132252 105px, transparent 110px),
        radial-gradient(circle at 500px 600px, transparent 255px, #2f222c 260px, #2f222c 265px, transparent 270px),
        radial-gradient(circle at 1500px 560px, transparent 195px, #2b183a 200px, #2b183a 205px, transparent 210px),
        radial-gradient(circle at 750px 100px, transparent 350px, #132252 355px, #132252 360px, transparent 365px),
        #020232;
}


.bg-color-3 {
    background:
        radial-gradient(circle at 100px 100px, rgba(44, 85, 107, 0.5) 20px, #00002e 70px),
        radial-gradient(circle at 150px 100px, rgba(63, 46, 45, 0.5) 20px, #00002e 70px),
        #00002e;
}

.bg-color-4 {
    background: linear-gradient(to right, #131351, #718b99, #e87c4f);
}

.bg-icon {
    // background: radial-gradient(to right, #ff6e47, #feb452);
    background: linear-gradient(135deg, #feb452 30%, #ff6e47 60%, #feb452 100%);
    height: 80px;
    /* 调整为需要的高度 */
    width: 80px;
    /* 调整为需要的宽度 */
    border-radius: 50%;
    /* 让div变为圆形 */
    overflow: hidden;
    /* 这会隐藏超出圆形的部分图片 */
    display: flex;
    /* 将元素设置为弹性容器 */
    justify-content: center;
    /* 让内容水平居中 */
    align-items: center;
    /* 让内容垂直居中 */
    margin: 20px auto;
    /* 让元素水平居中 */
}</style>