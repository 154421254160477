
import { sprintf } from 'sprintf-js';
import BigNumber from "bignumber.js";

export function numFormat(num) {
    try {
        if (num === 0) return 0;
        const [integer, decimal] = num.toString().split(".");
        const reversedArray = [...integer].reverse();
        const result = reversedArray.reduce((prev, cur, index) => {
            if (index % 3 === 0 && index !== 0) prev.push(",");
            prev.push(cur);
            return prev;
        }, []);
        const formattedInteger = result.reverse().join("");
        return decimal ? `${formattedInteger}.${decimal}` : formattedInteger;
    } catch (error) {
        //console.error(error);
        return 0;
    }
}


export function printf(template, ...args) {
    return sprintf(template, ...args);
}

export function formatMillions(number, decimalPlaces = 0) {
    const millions = number / 1_000_000;

    // 格式化为千位分隔符，并根据 decimalPlaces 参数设置小数位数
    const formatted = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    }).format(millions);

    return `${formatted}M`;
}

export function decimalPlaces(number, decimalPlaces = 2, useThousandsSeparator = false) {
    const bigNumber = new BigNumber(number).div(Math.pow(10, 18));
    const formattedNumber = bigNumber.toFixed(decimalPlaces);

    if (useThousandsSeparator) {
        const [integer, decimal] = formattedNumber.split(".");
        const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return decimal ? `${formattedInteger}.${decimal}` : formattedInteger;
    }

    return formattedNumber;
}
