<template>
    <div class="common-layout">
        <div id="particles-js" class="background-image"></div>
        <el-container style="width:100vw;height:100vh;">

            <el-header fixed style="flex:1;" class="flex align-items-center justify-content-center">
                <TopMenu :toggleVisible="!isNavbarToggleVisible" @menuClick="showOffcanvas" />
                
            </el-header>
            <el-scrollbar>
                <PopupMenu :visibleCanvas="visible" @updateVisible="updateVisible" />
                
                <FullScreenContainer>
                    <el-main class="flex justify-content-center"
                        :style="{ 'padding-bottom': isNavbarToggleVisible ? '20px' : '0' }">

                        <el-row :gutter="10" class="pine-main flex justify-content-start">
 
                                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                                    <div class="w-11" style="line-height:1.2;">
                                        <span class="title-text" style="color:#ffae01;">{{ $t('Intelligent-Platform') }}</span>
                                    </div>
                                    <div class="w-11" style="line-height:1.2;margin-top:10px;">
                                        <span class="title-desc">{{ $t('PineconeDAO Slogan') }}</span>
                                    </div>

                                </el-col>

                                <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20">
                                    <el-button style="margin-top:20px;" color="#ffae01" size="large" round
                                        v-show="isNavbarToggleVisible" @click="openApp">{{ $t('Launch App') }}</el-button>
                                </el-col>

                                <el-col :xs="23" :sm="20" :md="20" :lg="20" :xl="20" >
                                    <div class="icon-links" style="display: flex; flex-wrap: wrap;margin-top:20px;width:100%;">

                                        <el-link href="https://twitter.com/PineconeDAO" target="_blank" :underline="false">
                                            <i class="fa-brands fa-twitter" style="color: #ffae01;font-size:20px;margin-right:5px;margin-top:3px;"></i>
                                            <span class="logo-title" style="font-weight:600;">Twitter</span>
                                        </el-link>
                                        <el-link  href="https://discord.gg/DQzjt3SftC"
                                            target="_blank" :underline="false">
                                            <i class="fa-brands fa-discord" style="color: #ffae01;font-size:20px;margin-right:5px;margin-top:3px;"></i>
                                            <span class="logo-title" style="font-weight:600;">Discord</span>
                                        </el-link>
                                        <el-link 
                                            href="https://www.facebook.com/PineconeDAO" target="_blank"
                                            :underline="false">
                                            <i class="fa-brands fa-facebook" style="color: #ffae01;font-size:20px;margin-right:5px;margin-top:3px;"></i>
                                            <span class="logo-title" style="font-weight:600;">Facebook</span>
                                        </el-link>
                                        <el-link 
                                            href="https://t.me/pineconedao" target="_blank"
                                            :underline="false">
                                            <i class="fa-brands fa-telegram" style="color: #ffae01;font-size:20px;margin-right:5px;margin-top:3px;"></i>
                                            <span class="logo-title" style="font-weight:600;">Telegram</span>
                                        </el-link>
                                        
                                       

                                    </div>
                                </el-col>

                               

                        </el-row>

                    </el-main>
                </FullScreenContainer>

                <SummaryStats></SummaryStats>
                <HomeBody/>




                <Footer />
               

            </el-scrollbar>

        </el-container>
    </div>
</template>

<script>
// import ToggleButton from 'primevue/togglebutton';
// import Sidebar from 'primevue/sidebar';

// import Menubar from 'primevue/menubar';
// import { MenuItem } from 'primevue/api';

import { tsParticles } from 'tsparticles';
// import particlesConfig from '../assets/config/particlesConfig';
// import particles2 from '../assets/config/particles2';
import particles4 from '../assets/config/particles4';

import { ref, onMounted, onBeforeUnmount } from 'vue';

import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta';

import { useI18n } from 'vue-i18n';

import FullScreenContainer from '@/components/FullScreenContainer.vue';
import SummaryStats from '@/components/SummaryStats.vue';

import { useWeb3 } from '@/composables/useWeb3';
import TopMenu from '@/components/TopMenu.vue';
import PopupMenu from '@/components/PopupMenu.vue';
import Footer from '@/components/Footer.vue';
import HomeBody from '@/components/HomeBody.vue';

export default defineComponent({
    name: "IndexPage",
    components: {
        FullScreenContainer,
        SummaryStats,
        TopMenu,
        PopupMenu,
        Footer,
        HomeBody
    },
    setup() {
        const visible = ref(false);
        // const offcanvas = ref(null);
        function showOffcanvas() {
            visible.value = true;
        }

        // function hideOffcanvas() {
        //     visible.value = false;
        // }

        // function onHidden() {
        //     visible.value = false;
        // }

        function updateVisible(e) {
            visible.value = e;
        }


        const isNavbarToggleVisible = ref(false);

        onMounted(() => {
            window.addEventListener('resize', checkNavbarToggleVisibility);
            checkNavbarToggleVisibility();

            tsParticles.load('particles-js', particles4);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', checkNavbarToggleVisibility);
        });

        function checkNavbarToggleVisibility() {
            const navbarToggle = document.querySelector('.pine-navbar-toggle');
            isNavbarToggleVisible.value = navbarToggle && window.getComputedStyle(navbarToggle).display !== 'none';
        }


        useMeta({
            title: 'PineconeDAO',
            meta: [{
                name: 'description',
                content: 'The name PineconeDAO is derived from a story of wisdom and investment. In ancient times, there was a wise sage who lived in a forest surrounded by pine trees. The sage was renowned for his extraordinary wisdom and was sought after by many for financial investment advice. One day, a group of merchants came to the sage seeking advice on how to invest their wealth. The sage thought for a moment and then showed them a pinecone.'
            }]
        });

        const { locale } = useI18n();
        const changeLanguage = (newLocale) => {
            locale.value = newLocale;

            localStorage.setItem('locale', locale);
        }


        const openApp = () => {
            window.open('https://app.pineconedao.com', '_blank');
        };

        const openUrl = (url, target = '_blank') => {
            window.open(url, target);
        }

        const { connectWallet, disconnectWallet, coin, state } = useWeb3();

        return {
            locale,
            changeLanguage,
            isNavbarToggleVisible,
            visible,
            // offcanvas,
            showOffcanvas,
            // hideOffcanvas,
            // onHidden,
            openApp,
            openUrl,
            connectWallet,
            disconnectWallet,
            state,
            coin,
            updateVisible
        };
    }
});

</script>

<style lang="scss">
.background-image {
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 0;
    background-image: url('@/assets/img/home_bg.jpeg');
    /* 替换为你的图片路径 */
    background-size: cover;
    /* 让背景图片覆盖整个元素 */
    background-position: center;
    /* 让背景图片居中显示 */
    background-repeat: no-repeat;
    /* 防止背景图片重复 */
}

.background-image::before {
    content: "";
    /* 必须设置content属性 */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* 半透明的黑色 */
}

header {
    box-shadow: 0 1px 20px #33333359;
    background-color: rgba(0, 0, 0, 0.5);
    /* 透明度增加到0.7 */
    position: relative;
    z-index: 1;
}

.pine-close-button .btn-close {
    color: #fff !important;
}


.icon-links {
    display: flex;
    gap: 10px;
}

.el-icon-twitter-logo {
    background-image: url('@/assets/img/twitter-logo.svg');
    background-size: cover;
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
}

.el-icon-discord-logo {
    background-image: url('@/assets/img/discord-logo.svg');
    background-size: cover;
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
}




</style>